@use 'styles/master';
@use 'styles/animations';

.spinner {
   --size: 40px;
   --speed: .5s;
   --color: #{master.$primary};

   width: var(--size);
   height: var(--size);
   z-index: 100;

   &.static { position: static }
   &.absolute { position: absolute }
   &.fixed { position: fixed }

   &.none {
      top: auto;
      left: auto;
      bottom: auto;
      right: auto;
   }

   &.top-left,
   &.top-right { top: 10px }

   &.bottom-left,
   &.bottom-right { bottom: 10px }

   &.top-left,
   &.bottom-left { left: 10px }

   &.top-right,
   &.bottom-right { right: 10px }

   i {
      display: block;
      font-size: var(--size);
      color: var(--color);
      animation: rotate var(--speed) infinite linear;
   }
}
