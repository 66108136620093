@use 'styles/master';

.archives {
   position: relative;

   &.open {
      .toggle::after, .container { opacity: 1 }
      .container { pointer-events: auto }
   }

   &.warning {
      .toggle::before { opacity: 1 }
   }
}

.toggle {
   position: relative;
   padding: 6px;
   font-size: 20px;

   --icon-color: #{master.$text-dark};
   --icon-background: #{master.$block-light};
   --icon-color-hover: #{master.$block-light};
   --icon-background-hover: #{master.$text-dark};

   &::before, &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      opacity: 0;
      transition: opacity .2s;
   }

   &::before {
      top: 3px;
      right: 2px;

      width: 10px;
      height: 10px;

      border-radius: 50%;
      border: 1px solid #{master.$block-light};
      background-color: #{master.$error};
   }

   &::after {
      left: 50%;
      bottom: -14px;
      transform: translateX(-50%);

      width: 8px;
      height: 8px;

      border: 8px solid transparent;
      border-bottom-color: #{master.$primary};
   }
}

.container {
   position: absolute;
   top: 46px;
   right: -42px;

   display: grid;
   gap: 3px;

   padding: 10px 5px;
   width: auto;
   min-width: 180px;
   max-height: 300px;

   pointer-events: none;
   opacity: 0;

   border-top-left-radius: 0;
   border-top-right-radius: 0;

   box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2), 1px 12px 4px -10px rgba(34, 60, 80, 0.2) inset;
   transition: opacity .2s;
}

.archive {
   position: relative;
   padding: 2px 30px 2px 10px;

   white-space: nowrap;
   text-align: left;

   background-color: #{master.$block-light};
   transition: color .2s, background-color .2s;

   &::after {
      content: '';

      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);

      width: 8px;
      height: 8px;
      opacity: 0;

      border-radius: 50%;
      background-color: #{master.$primary};
      transition: opacity .2s;
   }

   &:hover {
      background-color: #{master.$secondary-lighter };
      &::after { opacity: .2 }
   }

   &.choosed {
      color: #{master.$primary};
      background-color: #{master.$primary-lighter};
      &::after { opacity: 1 }
   }
}
