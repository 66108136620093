.page {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px 65px;
   height: 100vh;
}

@media (max-width: 820px) {
   .page { padding: 20px }
}

@media (max-width: 500px) {
   .page { padding: 20px 10px }
}
