@use 'styles/master';
@use 'components/app/Router/Layout/SidebarLayout/layout';

.page {
   min-height: 100vh;
}

.darkside {
   position: fixed;
   left: #{layout.$close-width};
   z-index: 3;

   display: none;
   width: calc(100% - #{layout.$close-width});
   height: 100vh;

   opacity: 0;
   pointer-events: none;

   background-color: black;
   transition: left .3s, width .3s, opacity .3s;

   .close {
      --icon-color: #{master.$text-light};
      --icon-background: transparent;
      --icon-color-hover: #{master.$text-light};
      --icon-background-hover: transparent;

      position: absolute;
      top: 0;
      left: 0;

      margin: 15px 0 0 15px;
      opacity: .4;
      transition: opacity .2s, box-shadow 0.2s;

      &:hover, &:focus-visible {
         opacity: 1;
      }
   }
}

.content {
   position: relative;
   top: #{layout.$header-height};
   left: #{layout.$close-width};
   z-index: 1;

   width: calc(100% - #{layout.$close-width});
   transition: left .3s, width .3s;
}

.loader {
   height: calc(100vh - #{layout.$header-height * 2});
}

.main {
   position: relative;
   margin: 0 auto;
   padding: 20px 65px 60px;
   max-width: 1600px;
   min-height: calc(100vh - #{layout.$header-height});
}

.open .content {
   left: #{layout.$open-width};
   width: calc(100% - #{layout.$open-width});
}

@media (max-width: 1100px) {
   .darkside { display: block }

   .content,
   .open .content {
      left: #{layout.$close-width};
      width: calc(100% - #{layout.$close-width});
   }

   .open .darkside {
      left: #{layout.$open-width};
      width: calc(100% - #{layout.$open-width});

      opacity: .7;
      pointer-events: auto;
   }
}

@media (max-width: 950px) {
   .main { padding: 20px 20px 60px }
}

@media (max-width: 700px) {
   .content,
   .open .content,
   .page:not(.open) .darkside {
      left: 0;
      width: 100%;
   }

   .main { padding: 20px 15px 60px }
}
