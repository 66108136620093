@use 'styles/master';
@use 'components/app/Router/Layout/SidebarLayout/layout';

.header {
   position: fixed;
   left: #{layout.$close-width};
   z-index: 2;

   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 20px;

   width: calc(100% - #{layout.$close-width});
   height: #{layout.$header-height};

   background-color: #{master.$block-light};
   box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
   transition: left .3s, width .3s;
}

.crumbs {
   display: flex;
   align-items: center;

   .menu {
      margin: 0 15px;
      padding: 5px;
      font-size: 28px;

      --icon-color: #{master.$text-dark};
      --icon-background: #{master.$block-light};
      --icon-color-hover: #{master.$block-light};
      --icon-background-hover: #{master.$text-dark};
   }

   .title {
      overflow: hidden;
      max-width: 670px;

      text-overflow: ellipsis;
      white-space: nowrap;

      font-size: 24px;
      font-weight: 600;

      @media (max-width: 930px) { max-width: 600px }
      @media (max-width: 860px) { max-width: 500px }
      @media (max-width: 750px) { max-width: 420px; font-size: 20px }
      @media (max-width: 580px) { max-width: 320px; font-size: 18px }
      @media (max-width: 500px) { max-width: 280px }
      @media (max-width: 450px) { max-width: 220px }
      @media (max-width: 400px) { max-width: 160px }
   }
}

.services {
   display: flex;
   align-items: center;
   gap: 10px;

   padding-right: 15px;

   .logout {
      padding: 6px;
      font-size: 20px;

      --icon-color: #{master.$text-dark};
      --icon-background: #{master.$block-light};
      --icon-color-hover: #{master.$block-light};
      --icon-background-hover: #{master.$text-dark};
   }
}

.open {
   left: #{layout.$open-width};
   width: calc(100% - #{layout.$open-width});
}

@media (max-width: 1100px) {
   .header, .header.open {
      left: #{layout.$close-width};
      width: calc(100% - #{layout.$close-width});
   }
}

@media (max-width: 700px) {
   .header, .header.open {
      left: 0;
      width: 100%;
   }
}
