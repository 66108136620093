.page {
   position: relative;

   display: flex;
   justify-content: center;
   align-items: center;

   padding: 20px 65px 60px;
   min-height: 100vh;
}


@media (max-width: 820px) {
   .page { padding: 20px 20px 60px }
}

@media (max-width: 500px) {
   .page { padding: 20px 15px 60px }
}
