@use 'styles/master';
@use 'styles/animations';
@use 'components/app/Router/Layout/SidebarLayout/layout';
@include animations.createToTop(70%);

.sidebar {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 4;

   display: flex;
   flex-direction: column;

   width: #{layout.$close-width};
   height: 100vh;

   background-color: #{master.$sidebar};
   box-shadow: 4px 0px 6px 0px rgba(34, 60, 80, .25);
   transition: width .3s, left .3s;
}

.header {
   display: flex;
   align-items: center;

   width: 100%;
   height: #{layout.$header-height};

   background-color: #{master.$sidebar-darker};

   .logo {
      min-width: #{layout.$close-width};
      height: #{layout.$header-inner-height};

      text-align: center;
      line-height: #{layout.$header-inner-height};

      font-size: 28px;
      color: #{master.$primary};
   }

   .title {
      margin-left: -6px;

      white-space: nowrap;
      line-height: #{layout.$header-inner-height};

      font-size: 20px;
      font-weight: 600;
      color: #{master.$text-light};

      opacity: 0;
      pointer-events: none;
      transition: opacity .2s;
   }
}

.points {
   flex-grow: 1;
   overflow: visible;
   padding: 15px 0;
   max-height: calc(100% - #{layout.$header-height} - #{layout.$footer-height});

   &::-webkit-scrollbar { display: none }
}

.point {
   position: relative;
   list-style: none;
   padding: 0 8px;

   .container {
      position: relative;
      display: flex;
      align-items: center;

      width: 100%;
      color: #{master.$secondary};

      border-radius: 6px;
      background-color: #{master.$sidebar};
      transition: background-color .2s, box-shadow .2s;

      .icon {
         min-width: #{layout.$point-width};
         max-width: #{layout.$point-width};
         height: #{layout.$point-height};

         line-height: #{layout.$point-height};
         text-align: center;
         font-size: 18px;
      }

      .label {
         opacity: 0;
         pointer-events: none;
         font-size: 16px;
         font-weight: 500;
         transition: opacity .2s;
      }
   }

   .tooltip {
      padding: 10px 30px;

      a {
         text-decoration: none !important;
         line-height: 1;
         font-weight: 500;
         font-size: 16px;
         color: #{master.$text-light};
      }
   }

   &:hover {
      .container {
         color: #{master.$text-light};
         background-color: #{master.$sidebar-lighter};
      }

      .tooltip {
         top: 50%;
         transform: translateY(-50%);

         opacity: 1;
         pointer-events: auto;
      }
   }

   &:nth-child(1) .container .icon,
   &:nth-child(4) .container .icon {
      font-size: 20px;
   }

   &:not(:last-child) {
      margin-bottom: 5px;
   }

   &.active .container {
      color: #{master.$text-light};
      background-color: #{master.$sidebar-darker};
   }
}

.category {
   .arrow {
      margin-left: auto;
      font-size: 12px;

      display: none;
      transition: transform .3s;
   }

   .submenu {
      padding: 10px 0;
   }

   .subpoint {
      position: relative;
      list-style: none;
      padding: 0 20px;
      border-radius: 6px;

      a {
         padding: 5px 0;
         white-space: nowrap;
         font-weight: 400;
         color: #{master.$secondary};
         animation: to-top .5s;

         &:hover {
            color: #{master.$text-light};
         }
      }

      &.active a::before {
         content: '> '
      }
   }

   &:hover {
      .submenu {
         top: -2px;
         opacity: 1;
         pointer-events: auto;
      }

      .subpoint { animation: to-top .5s }
   }
}

.point .tooltip,
.category .submenu {
   position: absolute;
   top: -10px;
   left: 100%;

   display: block;
   opacity: 0;
   pointer-events: none;

   border-radius: 0 8px 8px 0;
   border: 2px solid #{master.$background};
   border-left: none;
   background-color: #{master.$sidebar-lighter};
   transition: top .3s, opacity .3s, transform .3s;
}

.footer {
   display: flex;
   align-items: center;

   overflow: hidden;
   padding: #{layout.$footer-gap} 0;
   width: #{layout.$close-width};
   height: #{layout.$footer-height};

   background-color: transparent;
   transition: width .3s, background-color .3s;

   .photo {
      display: flex;
      align-items: center;
      min-width: #{layout.$close-width};

      a {
         position: relative;
         margin: 0 14px 0 12px;
         width: #{layout.$photo-size};
         height: #{layout.$photo-size};
         border-radius: 16px;

         &:focus-visible img {
            padding: 0;
         }
      }

      img {
         padding: 10px;
         width: 52px;
         height: 52px;
         object-fit: cover;
         border-radius: 16px;
         background-color: #{master.$sidebar-darker};
         transition: padding .3s;
      }
   }

   .details {
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s;
   }

   .name, .role {
      max-width: #{layout.$details-width};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #{master.$text-light};
   }

   .name { font-size: 16px }

   .role {
      margin-top: -8px;
      opacity: .6;
      font-weight: 300;
      font-size: 14px;
   }
}

.open {
   &.sidebar { width: #{layout.$open-width} }

   .header .title {
      opacity: 1;
      pointer-events: auto;
   }

   .points {
      overflow: auto;
   }

   .point {
      .container {
         padding-right: 15px;

         .label {
            opacity: 1;
            pointer-events: auto;
         }
      }

      .tooltip {
         display: none;
      }
   }

   .category {
      .arrow { display: block }

      .submenu {
         position: static;
         top: auto;
         left: auto;

         margin-top: 5px;
         padding: 6px 0;
         opacity: 1;
         pointer-events: auto;

         border-radius: 6px;
         border: none;
      }

      .subpoint {
         padding: 0 6px 0 60px;
      }

      &.show {
         .container {
            color: #{master.$text-light};
            background-color: #{master.$sidebar-lighter};
         }

         .arrow { transform: rotate(180deg) }
      }

      &:hover .subpoint { animation: none }
   }

   .footer {
      width: #{layout.$open-width};
      background-color: #{master.$sidebar-darker};

      .photo img {
         padding: 0;
      }

      .details {
         opacity: 1;
         pointer-events: auto;
      }
   }
}

@media (max-width: 700px) {
   .sidebar:not(.open) {
      left: -#{layout.$close-width};
   }
}
