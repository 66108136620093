@use 'styles/master';

.section {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 60px 0;
}

.container {
   display: flex;
   gap: 24px;
   padding: 0 20px;
}

.status {
   font-weight: 800;
   font-size: 42px;
   color: #{master.$primary};
}

.info { max-width: 100% }

.message {
   padding-left: 24px;
   max-width: 700px;
   border-left: 1px solid #{master.$secondary};

   h1 {
      font-weight: 800;
      font-size: 42px;
   }

   p {
      margin-top: 4px;
      font-size: 16px;
      color: #{master.$secondary-hard};
   }
}

.buttons {
   margin-top: 40px;
   padding-left: 24px;

   display: flex;
   align-items: center;
   gap: 12px;
}

@media (max-width: 750px) {
   .status { font-size: 35px }
   .message {
      h1 { font-size: 35px }
      p { font-size: 14px }
   }
}

@media (max-width: 650px) {
   .section {
      top: 0;
      position: absolute;
   }

   .container {
      flex-direction: column;
      gap: 0;
      height: 100%;
   }

   .message {
      padding-left: 0;
      border-left: none
   }

   .buttons {
      margin-top: 25px;
      padding-left: 0;
   }
}

@media (max-width: 500px) {
   .status, .message h1 { font-size: 26px }
}
