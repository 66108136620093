@use 'styles/master';

.button {
   cursor: pointer;

   display: flex;
   align-items: center;
   justify-content: center;
   gap: 5px;

   line-height: 1;
   font-weight: 500;

   opacity: 1;
   border-radius: 6px;
   transition: background-color .2s, color .2s, box-shadow .2s, opacity .2s;

   &.disabled {
      cursor: not-allowed;
      opacity: .6;
   }
}

.primary,
.primary-lighter,
.primary-outlined {
   &:focus-visible {
      box-shadow: 0px 0px 0px 2px #{master.$block-light}, 0px 0px 0px 4px #{master.$primary};
   }
}

.primary {
   padding: 0.8em 1.2em;
   color: #{master.$text-light};
   background-color: #{master.$primary};

   &:not(.disabled):hover {
      background-color: #{master.$primary-hard};
   }
}

.primary-lighter {
   padding: 0.8em 1.2em;
   color: #{master.$primary};
   background-color: #{master.$primary-medium};

   &:not(.disabled):hover {
      background-color: #{master.$primary-darker};
   }
}

.primary-glass {
   padding: 0.8em 1.2em;
   color: #{master.$primary};
   background-color: #{master.$block-light};

   &:focus-visible {
      box-shadow: 0px 0px 0px 2px #{master.$primary}, 0px 0px 0px 4px #{master.$block-light};
   }

   &:not(.disabled):hover {
      background-color: #{master.$primary-medium};
   }
}

.primary-outlined {
   padding: calc(0.8em - 1px) calc(0.8em - 1px);
   color: #{master.$primary};
   border: 1px solid #{master.$primary};
   background-color: #{master.$block-light};

   &:not(.disabled):hover {
      color: #{master.$text-light};
      background-color: #{master.$primary};
   }
}

.link {
   display: inline-block;
   padding: 0;
   color: #{master.$primary};
   line-height: inherit;
   background-color: transparent;

   &:not(.disabled):hover {
      text-decoration: underline;
   }

   &:focus-visible { box-shadow: 0px 0px 0px 2px #{master.$text-dark} }
}

.icon {
   --icon-color: #{master.$text-dark};
   --icon-background: #{master.$secondary-lighter};
   --icon-color-hover: #{master.$primary};
   --icon-background-hover: #{master.$primary-medium};
   --button-background: #{master.$block-light};

   padding: 0.4em;
   color: var(--icon-color);
   background-color: var(--icon-background);

   &:focus-visible {
      box-shadow: 0px 0px 0px 2px var(--button-background), 0px 0px 0px 4px #{master.$text-dark};
   }

   &:not(.disabled):hover {
      color: var(--icon-color-hover);
      background-color: var(--icon-background-hover);
   }
}

.custom:not(.disabled):hover {
   color: var(--color-hover);
   background-color: var(--background-hover);
}

a.button.disabled { pointer-events: none }
