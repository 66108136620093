@use 'styles/master';

.scroll-x {
   overflow-x: auto;
   overflow-y: hidden;
}

.scroll-y {
   overflow-x: hidden;
   overflow-y: auto;
}

.scroll-xy {
   overflow-x: auto;
   overflow-y: auto;
}

.scroll-x, .scroll-y, .scroll-xy {
   &::-webkit-scrollbar-button:vertical:start:decrement,
   &::-webkit-scrollbar-button:vertical:end:increment {
      height: 0;
      background-color: transparent;
   }

   &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, .5);
   }
}

.block, .block-adaptive {
   width: 100%;
   padding: 10px 15px;
   border-radius: 6px;
   background-color: #{master.$block-light};
   box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.2);
}

.focus-light { --color: #{master.$text-light}; }
.focus-dark { --color: #{master.$text-dark}; }

.focus-light,
.focus-dark {
   border-radius: 6px;
   transition: box-shadow .2s;
   &:focus-visible { box-shadow: 0px 0px 0px 2px var(--color) }
}

.empty {
   margin: 0 auto;
   padding: 0 10px;
   list-style: none;
   text-align: center;
   color: #{master.$secondary-hard};
}

.m0auto {
   margin: 0 auto;
}

.confirm {
   display: flex;
   align-items: center;
   gap: 5px;

   i { font-size: 16px }
   p { font-weight: 500 }
}

.grid {
   display: grid;
   gap: 10px;
   width: 100%;
}

.flex, .flex-start, .row, .flex-center, .flex-wrap {
   display: flex;
   gap: 10px;
   width: 100%;
}

.flex-start, .row { align-items: flex-start }
.flex-center      { align-items: center }
.flex-wrap        { flex-wrap: wrap }

@media (max-width: 500px) {
   .row { flex-direction: column }
   .block-adaptive { padding: 10px }
}
