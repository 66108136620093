.ico {
   display: inline-block;

   font-family: 'icons';
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;

   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;

   &.arrow-down:before { content: "\e900" }
   &.arrow-left:before { content: "\e901" }
   &.arrow-right:before { content: "\e902" }
   &.arrow-up:before { content: "\e903" }
   &.archive:before { content: "\e928" }
   &.book:before { content: "\e904" }
   &.books:before { content: "\e905" }
   &.calendar:before { content: "\e906" }
   &.chart:before { content: "\e907" }
   &.check:before { content: "\e908" }
   &.close:before { content: "\e909" }
   &.cookie:before { content: "\e90a" }
   &.database:before { content: "\e90b" }
   &.email:before { content: "\e90c" }
   &.error:before { content: "\e90d" }
   &.exit:before { content: "\e90e" }
   &.eye:before { content: "\e90f" }
   &.eye-close:before { content: "\e910" }
   &.faq:before { content: "\e911" }
   &.filters:before { content: "\e912" }
   &.flasks:before { content: "\e913" }
   &.globe:before { content: "\e914" }
   &.home:before { content: "\e915" }
   &.info:before { content: "\e916" }
   &.layouts:before { content: "\e917" }
   &.logo:before { content: "\e918" }
   &.mail:before { content: "\e919" }
   &.menu:before { content: "\e91a" }
   &.messages:before { content: "\e91b" }
   &.newspaper:before { content: "\e91c" }
   &.plus:before { content: "\e91d" }
   &.puzzle:before { content: "\e91e" }
   &.refresh:before { content: "\e91f" }
   &.search:before { content: "\e920" }
   &.server:before { content: "\e921" }
   &.settings:before { content: "\e922" }
   &.spinner:before { content: "\e923" }
   &.success:before { content: "\e924" }
   &.trash:before { content: "\e927" }
   &.user:before { content: "\e925" }
   &.warning:before { content: "\e926" }
}
